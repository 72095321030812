// @import "~font-awesome-sass/assets/stylesheets/_font-awesome-compass.scss";
// @import "~font-awesome-sass/assets/stylesheets/_font-awesome.scss";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,300");

@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import 'spacer.scss';
@import 'variables.scss';


/** Generated by FG **/

@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url('/fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'), url('/fonts/HelveticaNeue-Medium.woff') format('woff'), url('/fonts/HelveticaNeue-Medium.ttf') format('truetype'), url('/fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('/fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'), url('/fonts/HelveticaNeue.woff') format('woff'), url('/fonts/HelveticaNeue.ttf') format('truetype'), url('/fonts/HelveticaNeue.svg#HelveticaNeue-Medium') format('svg');

  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'HelveticaNeue-Medium';
}

.login {
  h1 {
    color: $primary;
  }
}

.custom-search,
.theme-list {
  background: none !important;
}

header {
  background: #fff !important;
}

.custom-navigation {
  li {
    padding: 9px 13px;

    .custom-dropdown {
      padding: 0 !important;
    }
  }
}

.mainContent {
  &.ant-layout-content {
    margin-top: 65px !important;
    margin-left: 300px !important;
    background: #fff;

    &.collapsed {
      margin-left: 80px !important;
    }

  }

  .layout-trigger {
    font-size: 22px;
    margin-left: -24px;
    position: fixed;
    top: 65px;
  }
}


.sidebar-left {}

.brand-logo {
  font-size: 18px;
  margin-right: auto;

  img {
    width: 40px;
    height: 40px;
    padding: 5px;
  }

}

.custom-search {
  input {
    width: 40px;
    transition: 0.5s;
    height: 30px;
    background: none;
    color: $white;
    border: none;

    &:focus {
      width: 200px;
      transition: 0.5s;
      box-shadow: none;
      border-bottom: 1px solid $white;
      border-radius: 0;
    }
  }

  span {
    right: 0 !important;

    input:not(:last-child) {
      padding-right: 0 !important;
    }

    i {
      color: $white;
      margin-right: 0;

      &:hover {
        color: $white;
      }

    }
  }
}

.theme-list {
  height: 64px;
  display: flex !important;
  align-items: center;
  background: none !important;

  ul {
    width: 140px !important;
    text-align: center !important;
    margin-top: 60px !important;
    padding: 5px 0 !important;
    background: #e6f7ff !important;
    border-top: 3px solid #1890ff !important;
    border-bottom: 3px solid #1890ff !important;

    li {
      background: none !important;
      border: 1px solid #eee;
      border-right: 1px solid #eee !important;
      margin: 1px !important;
    }
  }
}

.theme-list-item {
  display: inline-flex !important;
  height: 40px;
  margin-bottom: 0 !important;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.profile-view {
  padding-right: 25px !important;
}

.language-list,
.profile-view {
  ul {
    background: #e6f7ff !important;
    border-top: 3px solid #1890ff;
    border-bottom: 3px solid #1890ff;

    li {
      color: $primary  !important;
    }
  }
}

.box,
.sub-box {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.box {
  background: $primary;
}

.sky-bg {
  background-color: $primary;
}

.green-bg {
  background-color: $success-color;
}

.red-bg {
  background-color: $error-color;
}

.purple-bg {
  background-color: $purple;
}

.dark-blue-bg {
  background-color: $dark-blue;
}

.grey-bg {
  background-color: $grey;
}

.stats {
  h2 {
    color: $white;
    font-family: 'HelveticaNeue';
    font-size: 18px;

  }

  .stats-number {
    font-family: 'HelveticaNeue-Medium';
    font-size: 32px;
  }

  img {
    height: 38px;
  }
}


* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow-x: auto !important;
}

h1 {
  font-family: 'HelveticaNeue-Medium' !important;
  font-size: 32px;
}

.mr-auto {
  margin-right: auto;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.btn-block {
  width: 100%;
}

.float-right {
  float: right;
}


.text-center {
  text-align: center;
}

.testimonials {
  small {
    color: $secondary-text;
    font-size: 12px;
  }

  i {
    color: $secondary-text;
    font-size: 18px;
  }

}

.gridView {
  div {
    text-align: center;
    padding: 16px 0;
    color: #fff;

    &:nth-child(odd) {
      background: $primary;
    }

    &:nth-child(even) {
      background: $light-blue;
    }
  }
}

.height-120 {
  height: 120px;
}

.height-100 {
  height: 100px;
}

.height-80 {
  height: 80px;
}

.height-50 {
  height: 50px;
}

.custom-col {
  padding: 16px 10px;
  background: $primary;
  color: $white;
}

.scrollable-container {
  height: 100px;
  overflow-y: scroll;

  .background {
    padding-top: 60px;
    height: 300px;
    background-image: url(https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg);
  }
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ant-advanced-search-form {
  padding: 24px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}


.profile-details {
  .user-image {
    height: auto;
    padding: 15px;
    box-shadow: 0px 0px 3px 2px #eee;

    img {
      width: 100%;
    }
  }

  .user-details {
    position: relative;

    .floating-icon {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 25px;

    }

    .user-ratings {
      h1 {
        margin-bottom: 0;
      }

      i {
        font-size: 22px;
        color: $primary;
      }
    }

    .user-buttons {
      button {
        border: 1px solid $primary;
        border-radius: 0;
        margin-right: 10px;
        font-size: 15px;
        font-weight: 500;
        padding: 8px 20px;
        box-shadow: none;
        height: auto;
        color: $primary;
        margin-bottom: 10px;

        &:hover {
          background: $primary;
          color: #fff !important;
        }
      }
    }

    .user-tab {
      span {
        font-weight: 600;
      }
    }
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin {
  svg {
    animation: spin 2s linear infinite;
  }
}

.personal-info {
  margin-bottom: 20px;

  h2 {
    margin-bottom: 0;
    color: $primary;
    font-size: 25px;
  }
}

.after-underline {
  font-size: 18px;
  position: relative;
  display: inline-block;
  padding-bottom: 8px;

  &:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid $primary;
  }
}

.contact-info {
  .contact-form {
    margin-bottom: 0 !important;

    div {
      line-height: 15px !important;
    }

    input,
    textarea {
      border: none;
      padding: 1px 0 !important;
      resize: none !important;
    }
  }
}

.skill-item {
  border-bottom: none !important;
  padding: 2px 0 !important;
  ;
  font-weight: 500;
}

.work-experience {
  h3 {
    margin-bottom: 0;
  }
}

.inner-sidebar {
  display: block;

  .ant-layout-sider-trigger {
    position: absolute;
  }

  .ant-layout-sider-children {
    .ant-menu-root {
      height: auto !important;

    }
  }
}

.demo-loadmore-list {
  min-height: 350px;
}

.auto {
  margin-left: auto !important;
}

.custom-rangepicker {
  .ant-calendar-picker {
    width: auto !important;
  }
}

.custom-pagination {
  li {
    margin-bottom: 10px;
  }
}

.ant-timeline-item-last {
  .ant-timeline-item-content {
    min-height: 30px !important;
  }

}

.custom-float {
  float: right;
}

.ant-layout {
  overflow-x: scroll !important;
}

@media only screen and (max-width: 1200px) {

  .controlled-checkbox,
  .custamized-input {
    margin-top: 10px;
  }

  .custom-margincards {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .custom-form-controls {
    .ant-col-lg-24.ant-form-item-label {
      padding: 8px 0 !important
    }
  }

  .custom-margintop {
    margin-top: 10px;
  }

  .custom-statcards,
  .custom-tocard,
  .custom-timeline {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 768px) {
  p {
    white-space: normal !important;

  }

  .intro {
    font-size: 32px !important;
  }

  .sm-block {
    display: block;
  }

  .custamized-input,
  .custom-margintop {
    margin-top: 10px;
  }

  .custom-float {
    display: block !important;
    float: none;
  }
}

@media only screen and (max-width: 576px) {
  .profile-details {
    .user-image {
      width: 250px;
      max-width: 100%;
    }
  }

  .ant-advanced-search-form {
    .ant-form-item-label {
      width: auto !important;
      margin-right: 5px;
      padding: 8px 0 !important;
    }
  }

  .custom-categories {
    padding: 10px 0 !important;
  }

  .dashboard {
    .ant-table {

      th,
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.auto {
  margin-left: auto !important;
}

.custom-rangepicker {
  .ant-calendar-picker {
    width: auto !important;
  }
}

.custom-pagination {
  li {
    margin-bottom: 10px;
  }
}

.socialicons-margin {
  margin: 7px 0;
}

@media only screen and (max-width: 1200px) {

  .controlled-checkbox,
  .custamized-input {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .custom-form-controls {
    .ant-col-lg-24.ant-form-item-label {
      padding: 8px 0 !important
    }
  }

  .custom-margintop {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {

  .custamized-input,
  .custom-margintop {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .ant-advanced-search-form {
    .ant-form-item-label {
      width: auto !important;
      margin-right: 5px;
      padding: 8px 0 !important;
    }

  }

  .sm-view {
    // display:flex;
    // flex-wrap: wrap;
    overflow: scroll;

    .scroll-item {
      min-width: 500px;

    }
  }

  .ant-radio-button-wrapper {
    padding: 0 6px !important;
  }
}

.header-toggle {
  position: absolute;
  top: 20px;
  z-index: 999;
  color: rgba(255, 255, 255, 0.65) !important;
  left: 25px;
  font-size: 28px;
}

.ant-fullcalendar-fullscreen {
  min-width: 480px;
  overflow: scroll;

}

.ant-steps-item:last-child>.ant-steps-item-container>.ant-steps-item-tail,
.ant-steps-item:last-child>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  display: none;
}


.ant-steps-vertical .ant-steps-item-title::after {
  display: none;
}

// .ant-steps-vertical .ant-steps-item-tail::after{
//   display:none;

// }

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #1890ff;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}

.ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
  display: block;
}

.ant-pagination-item {
  margin-bottom: 5px;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-bottom: 5px;
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px !important;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
  text-align: left !important;
}

.ant-tabs-nav-wrap {
  position: relative !important;
  display: inline-block !important;
  flex: auto !important;
  align-self: stretch !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  transform: translate(0) !important;
}