.ant-input[disabled] {
  background-color: #f5f5f5 !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f5f5f5 !important;
  opacity: 1;
}

.ant-input-number-disable {
  background-color: #f5f5f5 !important;
  opacity: 1;
}

input:disabled {
  background-color: #f5f5f5 !important;
}

.ql-disabled {
  background-color: #f5f5f5;
}

.pjo-status {
  margin: auto 0px;
  font-size: 14px;
}